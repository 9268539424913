#Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: #2D0463;
    color: #ffffff;
    font-weight: 500;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.privacy-policy a {
    color: #ffffff;
}

.privacy-policy a:hover {
    color: #ffffff;
}


@media only screen and (max-width: 768px) {
    #Footer {
        /* width: 100%; */
        height: 100px;
        background-color: #2D0463;
        position: static;
        bottom: 0;
        /* width: 100%; */
    }
}