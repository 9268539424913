.logo-section img {
    width: 20%;
    height: 14%;
    margin-top: 57px;
    margin-left: 10%;
}

label {
    color: #818181;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal;
    text-transform: capitalize; */
}

input {
    padding-right: 30px;
    padding-left: 15px;
    width: 320px;
    height: 40px;
    border: 1px solid rgba(198, 194, 254, 1);
    border-radius: 6px;
}

.exchange-button {
    width: 320px;
    height: 40px;
    margin-top: 22px;
    border: rgba(198, 194, 254, 1);
    background-color: rgb(35, 27, 115);
    border-radius: 6px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 18px;
}

.tab-container{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.tab-section {
    Width: 400px;
    height: auto;
    margin-top: 67px;
    background-color: #ffffff;
    padding: 5px 0px 3px 3px;
    border-radius: 10px;
    margin-bottom: 15vh;
}

.admin-dashboaed-heading{
    margin-left: 11%;
    margin-top: 48px;
    color: #ffffff;
    font-size: 37px;
}

.form-section {
    margin-left: 35px;
    margin-right: 30px;
}

@media only screen and (max-width: 450px){
    .logo-section img {
        width: 35%;
        height: 14%;
        margin-top: 57px;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 750px){
    .logo-section img {
        width: 30%;
        height: 14%;
        margin-top: 57px;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 1000px){
    .logo-section img {
        width: 20%;
        height: 14%;
        margin-top: 57px;
        margin-left: 10%;
    }
}