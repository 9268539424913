.logo-section img {
    width: 20%;
    height: 14%;
    margin-top: 57px;
    margin-left: 11%;
}

.heading-title p {
    margin-left: 11%;
    margin-top: 48px;
    color: #ffffff;
    font-size: 37px;
}

.tab-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#tab-section {
    Width: 400px;
    height: auto;
    margin-top: 67px;
    background-color: #ffffff;
    padding: 5px 0px 3px 3px;
    border-radius: 10px;
    margin-bottom: 15vh;
}

.Payment-method {
    font-weight: 600;
    font-size: 18px;
    color: rgba(47, 46, 46, 1);
}


.radio-inline input {
    float: left;
    width: 25px;
    height: 25px;
}

.radio-inline {
    font-size: 16px;
    font-weight: 500;
    margin-top: 2px;
    color: rgba(48, 39, 135, 1);
}

.radio-inline label {
    clear: both;
}


/* .radio-inline input{
    display: flex;
    flex-direction: row;
} */

.ekyc-section {
    margin-top: 6%;
    margin-left: 11%;
}

.steps-section {
    margin-top: 6%;
    margin-left: 11%;
    margin-bottom: 15vh;
}

#exchange-section-mobile {
    content-visibility: hidden;
}

.ekyc-heading {
    color: #FFF;
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.card-title {
    color: #FFF;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.text-coin {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
}

.title-space {
    letter-spacing: 2px;
    /* color: #000000; */
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}

.step-title-2 {
    color: #BA45FD;
    font-size: 16px;
    font-weight: 600;
}

.step-title-3 span {
    font-size: 25px;
    /* position: absolute; */
    top: 30px;
    color: #edd7ae;
}


/* @media only screen and (max-width: 768px) {
    .padding-text {
        padding-left: 0px;
    }
} */

.step-title-4 {
    color: #F2DBFF;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
}

/* .black-text {
    color: #000 !important;
} */

.step-button {
    width: 120px;
    height: 37px;
    border: none;
    border-radius: 25px;
    color: black;
    background-color: #f2951d;
}


.Buy-section {
    font-size: 22px;
}


/* INPUT feildd sections */

/* IconInput.css */
.icon-input {
    position: relative;
    display: inline-block;
}

input {
    padding-right: 30px;
    padding-left: 15px;
    width: 320px;
    height: 40px;
    border: 1px solid rgba(198, 194, 254, 1);
    border-radius: 6px;
}

#textarea {
    padding-right: 30px;
    padding-left: 15px;
    width: 320px;
    height: 40px;
    border: 1px solid rgba(198, 194, 254, 1);
    border-radius: 6px;
}

#textarea-address {
    padding-right: 30px;
    padding-left: 15px;
    width: 320px;
    height: 40px;
    border: 1px solid rgba(198, 194, 254, 1);
    border-radius: 6px;
}

.icon {
    position: absolute;
    top: 50%;
    right: 45px !important;
    transform: translateY(-50%);
}

.usdt-icon {
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);
}

.usd-icons {
    position: absolute;
    top: 50%;
    right: 13px !important;
    color: #2F2E2E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    transform: translateY(-50%);
}

.icons {
    position: absolute;
    top: 50%;
    right: 26px !important;
    color: #2F2E2E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    transform: translateY(-50%);
}

label {
    color: #818181;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal;
    text-transform: capitalize; */
}


#exchange-button {
    width: 320px;
    height: 40px;
    margin-top: 22px;
    border: rgba(198, 194, 254, 1);
    background-color: rgb(35, 27, 115);
    border-radius: 6px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 18px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-section {
    margin-left: 20px;
    margin-right: 30px;
}

.error-message {
    font-weight: 500;
    font-size: 10.5px;
    color: rgba(185, 67, 60, 1);
}

#content {
    width: '30%';
    height: "auto";
    top: '10%';
    left: '50%';
    right: 'auto';
    bottom: 'auto';
    margin-right: '-50%';
    transform: 'translate(-50%, -50%)';
    border-radius: '20px';
}


@media only screen and (max-width: 750px) {
    #tab-section {
        width: 400px;
        height: auto;
        /* margin-top: 67px; */
        background-color: #ffffff;
        padding: 5px 0px 3px 3px;
        border-radius: 10px;
        margin-bottom: 0vh;
    }

    .ekyc-heading {
        color: #FFF;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .heading-title p {
        margin-top: 30px;
        color: #ffffff;
        font-size: 25px;
    }

    .steps-section {
        margin-top: 0px;
        margin-left: 0px;
        display: none;
    }

    #exchange-section-mobile {
        content-visibility: visible;
        margin-bottom: 5vh;
    }
}

@media only screen and (max-width: 370px) {
    #tab-section {
        Width: 370px;
        Height: auto;
        /* margin-top: 67px; */
        background-color: #ffffff;
        padding: 5px 0px 3px 3px;
        border-radius: 10px;
    }

    .ekyc-heading {
        color: #FFF;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
}

@media only screen and (max-width: 990px) {
    .steps-section {
        margin-top: 0px;
        margin-left: 0px;
        display: none;
    }

    #exchange-section-mobile {
        content-visibility: visible;
        margin-bottom: 5vh;
    }

    #tab-section {
        Width: 400px;
        Height: auto;
        margin-top: 45px;
        background-color: #ffffff;
        padding: 5px 0px 3px 3px;
        border-radius: 10px;
        margin-bottom: 0vh;
    }
}

@media only screen and (max-width: 450px) {
    #tab-section {
        Width: 380px;
        Height: auto;
        /* margin-top: 67px; */
        background-color: #ffffff;
        padding: 5px 0px 3px 3px;
        border-radius: 10px;
    }

    #exchange-section-mobile {
        content-visibility: visible;
        margin-bottom: 3vh;
    }

    .step-title-4 {
        color: #F2DBFF;
        font-family: Inter;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: capitalize;
    }

    .logo-section img {
        width: 30%;
        height: 14%;
        margin-top: 57px;
        margin-left: 11%;
    }
}

@media(width:768px) {
    /* #tab-section {
        Width: 80%;
        Height: auto;
        margin-top: 67px;
        background-color: #ffffff;
        padding: 5px 0px 3px 3px;
        border-radius: 10px;
    } */

    .steps-section {
        margin-top: 0px;
        margin-left: 0px;
    }

    .heading-title p {
        margin-top: 30px;
        color: #ffffff;
        font-size: 37px;
    }

    #exchange-section-mobile {
        content-visibility: visible;
        margin-bottom: 5vh;
    }
}

