/* IconInput.css */
.icon-input {
    position: relative;
    display: inline-block;
  }
  
  input {
    padding-right: 80px;
    width: 320px;
    height: 40px;
     /* Adjust this value to make room for the icon */
  }
  
  .icon {
    position: absolute;
    top: 50%;
    right: 50px; /* Adjust this value for icon spacing */
    transform: translateY(-50%);
  }
  .icons {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value for icon spacing */
    transform: translateY(-50%);
  }
  